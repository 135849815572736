<script setup>
import { useUserStore } from '~/stores/user.store';
const userStore = useUserStore();
const user = computed(() => userStore.userGetter());
const props = defineProps({
  country_id: {
    type: Number,
    required: true,
  }
})
const layoutStore = useLayoutStore();

const menus = ref([]);

const trimSlash = (string) => {
  return string.replace(/^\/+|\/+$/g, '');
}

const menusInHeaders = await layoutStore.fetchMenusInHeader('blog', props.country_id);
menus.value = menusInHeaders;
</script>
<template>
  <div class="w-full flex gap-2 snap-x overflow-x-auto ">
    <template v-for="menu in menus" :key="menu._id">
      <div v-if="menu.only_for_logged_in_users===0||user!=undefined" class="snap-start  shrink-0 bg-gray-200 p-2 text-sm rounded-lg text-gray-600"
        :class="{ [trimSlash($route.fullPath) == trimSlash(menu.url) ? 'text-indigo-800' : 'text-gray-600']: true }">
        <NuxtLink :to="localePath({ path: menu.url })">{{ menu.name }} mins read</NuxtLink>
      </div>
    </template>
  </div>
</template>